.header-brand-img{
  height:4rem !important;
}

.d-flex.order-lg-2.ml-auto .dropdown{
  align-self: center;
}
.background-container{
  background: url("../images/home_bg.png") no-repeat;
  background-position: bottom center;
  background-size: 1500px;
}